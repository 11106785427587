import { AuthType } from '@helpers/AuthClient';
import ResetPasswordView from '@views/ResetPasswordView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const ResetPasswordAppPage: FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ResetPasswordView authType={AuthType.App} />
    </>
  );
};

export default ResetPasswordAppPage;
