/* eslint-disable jsx-a11y/no-autofocus */
import PageMeta from '@components/PageMeta';
import TextField from '@components/TextField';
import Translate from '@components/Translate';
import { useAuth } from '@context/AuthContext';
import { AuthType } from '@helpers/AuthClient';
import getAuthPageUrl from '@helpers/getAuthPageUrl';
import isBrowser from '@helpers/isBrowser';
import isValidPassword from '@helpers/isValidPassword';
import useTranslation from '@helpers/useTranslation';
import AuthTemplate from '@templates/AuthTemplate';
import { Link, navigate } from 'gatsby';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Content, Form, Message } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  newPassword: string;
}

interface ResetPasswordViewProps {
  authType: AuthType;
}

const ResetPasswordView: FC<ResetPasswordViewProps> = ({ authType }) => {
  const { t } = useTranslation(['auth', 'validation', 'pages']);

  const { authClient } = useAuth();

  const formProps = useForm<FormValues>();

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const code = useMemo(() => {
    const searchParams = isBrowser ? new URLSearchParams(window.location.search) : null;

    return searchParams?.get('code') ?? null;
  }, []);

  useEffect(() => {
    if (isBrowser && !code) {
      setErrorMessage(t('resetPasswordCodeMissing'));
    }
  }, [code, t]);

  const handleFormSubmit: SubmitHandler<FormValues> = ({ newPassword }) => {
    setErrorMessage(null);
    setIsLoading(true);

    if (!code) {
      throw new Error('Reset password code is missing.');
    }

    authClient
      .resetPassword(code, newPassword)
      .then(() => {
        navigate(loginUrl, {
          state: {
            message: t('resetPasswordSuccess'),
          },
        });
      })
      .catch(error => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const forgotPasswordUrl = getAuthPageUrl('forgotPassword', authType);
  const loginUrl = getAuthPageUrl('login', authType);
  const registerUrl = getAuthPageUrl('register', authType);

  return (
    <AuthTemplate>
      <PageMeta
        description={t('pages:resetPasswordDescription')}
        title={t('pages:resetPassword')}
      />
      <AuthTemplate.Content>
        {errorMessage && (
          <Message color="danger">
            <Message.Body>{errorMessage}</Message.Body>
          </Message>
        )}
        <Content>
          <p>{t('resetPasswordInstructions')}</p>
        </Content>
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
            <TextField<FormValues>
              Caption={
                <>
                  {t('minimumEightCharacters')}
                  <br />
                  {t('containsMixedCase')}
                  <br />
                  {t('containsNumberAndSymbol')}
                </>
              }
              id="password"
              label={t('newPassword')}
              name="newPassword"
              type="password"
              validation={{
                required: t('validation:required', { field: t('password') }),
                validate(password) {
                  return isValidPassword(password) ? true : t('validation:invalidPassword');
                },
              }}
            />
            <Form.Field alignItems="center" kind="group">
              <Form.Control>
                <Button color="primary" disabled={!code} loading={isLoading}>
                  {t('common:submit')}
                </Button>
              </Form.Control>
              <Link to={forgotPasswordUrl}>{t('dontHaveCode')}</Link>
            </Form.Field>
          </form>
        </FormProvider>
        <AuthTemplate.Footer>
          <Translate
            components={{
              login: <Link to={loginUrl} />,
              signUp: <Link to={registerUrl} />,
            }}
            message={t('haveOrNeedAccount')}
          />
        </AuthTemplate.Footer>
      </AuthTemplate.Content>
    </AuthTemplate>
  );
};

export default ResetPasswordView;
